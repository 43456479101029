import { Dropdown, Menu, Typography, Space } from 'antd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import './style.less';
import { useFetchDetail } from 'hooks';
import resourceQuery from 'models/resource/query';
interface PropsHeader {
  dataUser?: any;
  handleLogout?: () => void;
}

const HeaderComponent: React.FC<PropsHeader> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: inquiry } = useFetchDetail<any>({
    ...resourceQuery.settingValue,
    apiUrl: resourceQuery.settingValue.apiUrl + '/inquiryLink',
  });
  // const items = [
  //   {
  //     label: (
  //       <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
  //         1st menu item
  //       </a>
  //     ),
  //     key: 'item-0',
  //   },
  //   {
  //     label: (
  //       <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
  //         2nd menu item
  //       </a>
  //     ),
  //     key: 'item-1',
  //   },
  // ];

  return (
    <Space className="header-user" align="center">
      {/* <Dropdown
        trigger="hover"
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => {
                history.push('/setting');
              }}
            >
              <Typography.Text>{t('menuAvatar:setting')}</Typography.Text>
            </Menu.Item>
            <Menu.Item>
              <Typography.Text>{t('menuAvatar:guide')}</Typography.Text>
            </Menu.Item>
            <Menu.Item>
              <Typography.Text>{t('menuAvatar:contact')}</Typography.Text>
            </Menu.Item>
            <Menu.Item onClick={props.handleLogout}>
              <Typography.Text>{t('menuAvatar:logout')}</Typography.Text>
            </Menu.Item>
          </Menu>
        }
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <Typography className="user-name">
              {` ${_get(props.dataUser, 'username') || _get(props.dataUser, 'fullName', '')}`}
            </Typography>
            <div className="avatar-icon" style={{ color: _get(props.dataUser, 'avatarColor') }}>
              <AccountCircleIcon />
            </div>
          </Space>
        </a>
      </Dropdown> */}

      <Space>
        <Typography className="user-name">
          {` ${_get(props.dataUser, 'username') || _get(props.dataUser, 'fullName', '')}`}
        </Typography>
      </Space>
      <Dropdown
        trigger={['hover']}
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => {
                history.push('/setting');
              }}
            >
              <Typography.Text>{t('menuAvatar:setting')}</Typography.Text>
            </Menu.Item>
            {/* <Menu.Item>
              <Typography.Text>{t('menuAvatar:guide')}</Typography.Text>
            </Menu.Item> */}
            <Menu.Item
              onClick={() => {
                window.open(_get(inquiry, 'value'), '_blank', 'noreferrer');
              }}
            >
              <Typography.Text>{t('menuAvatar:contact')}</Typography.Text>
            </Menu.Item>
            <Menu.Item onClick={props.handleLogout}>
              <Typography.Text>{t('menuAvatar:logout')}</Typography.Text>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        overlayClassName="menu-avatar-overlay"
      >
        <a onClick={(e) => e.preventDefault()}>
          <div className="avatar-icon" style={{ color: _get(props.dataUser, 'avatarColor') }}>
            <AccountCircleIcon />
          </div>
        </a>
      </Dropdown>
    </Space>
  );
};

export default HeaderComponent;
